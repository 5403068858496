import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { customDetector } from '../customDetector';

const useLanguageNavigator = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { changeLanguage } = useLanguage();

  const handleChangeLanguage = (newLanguage) => {
    changeLanguage(newLanguage);
	// Store the user's selected language in localStorage
	customDetector.cacheUserLanguage(newLanguage);

    const currentPath = location.pathname;

    // Define the new language prefix
    const newLanguagePrefix = newLanguage === 'fr' ? '/fr' : '';

    // Remove any existing language prefix from the current path
    const cleanedPath = currentPath.replace(/^\/fr/, '');

    // Determine the new path with the new language prefix
    const newPath = `${newLanguagePrefix}${cleanedPath}`;

    // Navigate to the new path
    navigate(newPath, { replace: true });
  };

  return { handleChangeLanguage };
};

export default useLanguageNavigator;
