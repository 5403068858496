import { useTranslation } from 'react-i18next';
import MetaTags from './MetaTags';

function NotFoundPage() {
	const { t } = useTranslation(['not_found']);

	return (<>
		<MetaTags />

		<h1>{ t('title', { ns: 'not_found' }) }</h1>
		<p>{ t('message', { ns: 'not_found' }) }</p>
	</>);
}

NotFoundPage.namespaces = ['not_found'];

export default NotFoundPage;