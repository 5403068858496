import { useTranslation } from 'react-i18next';

import MetaTags from './MetaTags';
import LocalizedLink from './LocalizedLink';

import photoMaman from '../assets/photo-Maman.png';

import '../stylesheets/about.css';

function AboutPage() {
	const { t } = useTranslation(['about']);
	return (<>
		<MetaTags />
		
		<h1>{ t('title', { ns: 'about' }) }</h1>
		<div id="Maman">
			<img src={photoMaman} alt="Guénolée Milleret" />
		</div>
		<div>
			<p>{ t('bio', { ns: 'about' }) }</p>
		</div>
		<div className="lien-nav">
			<LocalizedLink to="/books">{ t('link_books', { ns: 'about' }) }</LocalizedLink>
		</div>
		<div>
			<h2>Podcasts</h2>
			<ul>
				<li><a href="https://www.radiofrance.fr/franceculture/podcasts/serie-coco-chanel-l-ombre-du-luxe">Coco Chanel, l'ombre du luxe</a>, 4 { t('episodes', { ns: 'about' }) }, France Culture</li>
				<li><a href="https://www.radiofrance.fr/franceculture/podcasts/entendez-vous-l-eco/l-economie-selon-jeanne-lanvin-6466969">L'économie selon Jeanne Lanvin</a>, 22/02/2024, <i>Entendez-vous l'éco ?</i> (Épisode 95/113), France Culture</li>
				<li><a href="https://www.radiofrance.fr/franceculture/podcasts/entendez-vous-l-eco/l-economie-selon-coco-chanel-5593990">L'économie selon Coco Chanel</a>, 23/06/2023, <i>Entendez-vous l'éco ?</i> (Épisode 70/113), France Culture</li>
				<li><a href="https://www.ifmparis.fr/fr/podcasts/haute-couture-un-art-de-vivre-a-la-francaise">Haute couture : un art de vivre à la française&nbsp;?</a>, 03/02/2017, Institut Français de la Mode</li>
			</ul>
		</div>
	</>);
}

AboutPage.namespaces = ['about'];

export default AboutPage;