import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { customDetector } from './customDetector'; // Import your custom detector

// List of supported languages
const fallbackLanguage = 'en'; // default fallback language
const availableLanguages = ['en', 'fr'];

i18n.use(Backend)	// load translation using http
    .use(LanguageDetector)	// detect user language
	.use(initReactI18next)	// pass the i18n instance to react-i18next
    .init({
		debug: false,
        fallbackLng: fallbackLanguage,
		supportedLngs: availableLanguages,
		load: 'languageOnly',
		cleanCode: true,
        detection: {
			order: ['querystring', 'customDetector', 'localStorage', 'sessionStorage', 'navigator'], // Detection order
			lookupFromPathIndex: 1, // Index of the custom detector in the order array
			checkForSupportedLngs: true, // Only detect supported languages
			fallbackLng: fallbackLanguage,
            supportedLngs: availableLanguages
		},
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        ns: ['common', 'db'], // Load both common and db namespaces
        defaultNS: 'common', // Set the default namespace to common
    })

// Add your custom detector directly to i18next
i18n.services.languageDetector.addDetector(customDetector);

i18n.on('initialized', (options) => {
	console.log('i18next initialized:', options);
});

i18n.on('loaded', (loaded) => {
	console.log('i18next loaded:', loaded);
});

i18n.on('failedLoading', (lng, ns, msg) => {
	console.error(`i18next failed loading: ${lng}, ${ns}, ${msg}`);
});

export default i18n