// hooks/useLocalizedPath.js
import { useLanguage } from '../contexts/LanguageContext';

const useLocalizedPath = (path) => {
    const { language } = useLanguage();
    const languagePrefix = language === 'fr' ? '/fr' : '';
    return path.startsWith('/') ? `${languagePrefix}${path}` : path;
};

export default useLocalizedPath;
