import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from './MetaTags';
import { useLanguage } from '../contexts/LanguageContext';
import PDFViewer from './PDFViewer';
import CGU_fr from '../assets/CGU_fr.pdf';
import CGU_en from '../assets/CGU_en.pdf';

import '../stylesheets/tos.css';

function TosPage() {
	const { t } = useTranslation(['tos']);
	const { language } = useLanguage();

	// Memoize content for English and French
	const english_content = useMemo(() => (
		<div className='CGU'>
			<a href="https://imagesguenomiller.com/CGU_en.pdf" rel="noreferrer nofollow" target="_blank">Open in a new tab</a>
			<PDFViewer pdf={CGU_en} />
		</div>
	), []);

	const french_content = useMemo(() => (
		<div className='CGU'>
			<a href="https://imagesguenomiller.com/CGU_fr.pdf" rel="noreferrer nofollow" target="_blank">Ouvrir dans un nouvel onglet</a>
			<PDFViewer pdf={CGU_fr} />
		</div>
	), []);

	const [content, setContent] = useState(language === 'fr' ? french_content : english_content);

	// Update content based on language
	useEffect(() => {
		setContent(language === 'fr' ? french_content : english_content);
	}, [language, french_content, english_content]);
	
	return (
		<>
			<MetaTags />
			<h1>{t('title', { ns: 'tos' })}</h1>
			{content}
		</>
	);
}

TosPage.namespaces = ['tos'];

export default TosPage;