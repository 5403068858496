import { useTranslation } from 'react-i18next';

import MetaTags from './MetaTags';

import '../stylesheets/galerie.css';
import '../stylesheets/compositions.css';

function Item(props) {
	let img_src = require(`../assets/composition-${props.number}.jpg`);

	return (
		<div className="item">
			<img src={img_src} alt="Composition" />
		</div>
	);
}

function CompositionsPage() {
	const { t } = useTranslation(['compositions']);

	return (<>
		<MetaTags />

		<h1>{ t('title', { ns: 'compositions' }) }</h1>
		<div>
			<p>{ t('quote', { ns: 'compositions' }) }</p>
			<p id="auteur">{ t('author', { ns: 'compositions' }) }</p>
		</div>
		<div className="galerie">
		{Array.from({ length: 11 }).map((_, i) => (
		<Item key={i} number={i} />
	))}
		</div>
	</>);
}

CompositionsPage.namespaces = ['compositions'];

export default CompositionsPage;