import { Link as RouterLink } from 'react-router-dom';
import useLocalizedPath from '../hooks/useLocalizedPath'; // Import the custom hook

const LocalizedLink = ({ to, children, ...props }) => {
	// Prepend the language prefix to the `to` prop
	const newPath = useLocalizedPath(to);

	return (
	<RouterLink to={newPath} {...props}>
		{children}
	</RouterLink>
	);
};

export default LocalizedLink;
