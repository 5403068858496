import { useTranslation } from 'react-i18next';

import MetaTags from './MetaTags';

function ForbiddenPage() {
	const { t } = useTranslation(['forbidden']);
	
	return (<>
		<MetaTags />
		
		<h1>{ t('title', { ns: 'forbidden' }) }</h1>
		
		<p>{ t('message', { ns: 'forbidden' }) }</p>
	</>);
}

ForbiddenPage.namespaces = ['forbidden'];

export default ForbiddenPage;