import '../stylesheets/pdf_viewer.css';

function PDFViewer(props) {
	return (
 		<div className="pdf-viewer">
			<iframe src={props.pdf} allow="fullscreen" title="PDF Viewer" />
 		</div>
	);
};

export default PDFViewer;