import { useTranslation } from 'react-i18next';

import MetaTags from './MetaTags';

import '../stylesheets/books.css';

import streatwear from '../assets/Le-Streetwear.jpg';
import petitMuseeModes from '../assets/Le-petit-musee-des-modes.jpg';
import modeXIXe from '../assets/La-mode-du-XIXe-siecle-en-images.jpg';
import decor from '../assets/Le-decor-interieur-en-images.jpg';
import architecture from '../assets/Dessin-d-architecture-et-habitat-moderne.jpg';
import hauteCouture from '../assets/Haute-couture.jpg';
import vitrines from '../assets/Les-vitrines-du-luxe.jpg';
import follesHeures from '../assets/Les-folles-heures-de-la-vie-d-une-parisienne.jpg';
import fantomes from '../assets/Histoire-de-la-mode-et-de-la-cuisine-en-6-escales.jpg';
import cousettes from '../assets/Les-cousettes-du-Petit-Echo.jpg';
import brodeur from '../assets/L-atelier-du-brodeur.jpg';
import modeliste from '../assets/L-atelier-du-modeliste.jpg';

function BooksPage() {
	const { t } = useTranslation(['books']);
	return (<>
		<MetaTags />

		<h1>{ t('title', { ns: 'books' }) }</h1>

		<h2>{ t('title-Atelier-de', { ns: 'books' }) }</h2>
		<div className="grille-livre">
			<div className="titre-livre">
				<h3>L'Atelier du modéliste</h3>
			</div>
			<div className="infos">
				<img src={modeliste} alt="Cover" />
				<p>Publishroom Factory ({ t('Publishroom', { ns: 'books' }) })</p>
				<p>02/09/2024</p>
				<a href="https://www.publishroom.com/librairie/1365-53512-l-atelier-du-modeliste-de-guenolee-milleret.html">{ t('link', { ns: 'books' }) }</a>
			</div>
			<div className="resume">
				<p>Modéliste… Hum&nbsp;! Celui qui fabrique des maquettes d'avion&nbsp;? La confusion est récurrente chez les non-initiés. Quant aux spécialistes, ils sont aussi en peine&nbsp;: connaît-on seulement le nom du modéliste qui a conçu la toile de telle veste iconique chez Balenciaga ou Vuitton&nbsp;? Risquons le jeu de mots&nbsp;: cet artisan est aussi <i>flou</i> que l'atelier où il travaille, aussi <i>transparent</i> que les mousselines qu'il façonne&nbsp;!</p>
				<p>Il est grand temps de dresser le portrait du modéliste, acteur clé de la création vestimentaire. Ses racines&nbsp;? À rechercher dans les ateliers <i>tailleur</i> et <i>flou</i> de la haute couture parisienne. Sa mission&nbsp;? Exprimer un dessin en volume. Ses méthodes&nbsp;? Coupe à plat et moulage. Ses outils&nbsp;? Épingles, ciseaux et mannequin buste. Au XXe siècle, l'innovation est indissociable des gestes de Vionnet, Grès, Dior, Balenciaga, Saint Laurent ou encore Alaïa&nbsp;: tous de grands couturiers certes, mais éminents modélistes avant tout.</p>
				<p>Et aujourd'hui, qui sont les modélistes&nbsp;? L'auteure ouvre une à une les portes de leurs discrets ateliers à la rencontre de Jean-Marc Lespinet, Dorota Turowska-Vermelin, Daniel Fumaz puis Delphine Boissinot sans se priver du plaisir d'interviewer leurs proches collaborateurs, les <i>petites mains</i> de la haute couture. Des témoignages éclairants, savoureux et follement inspirants…</p>
			</div>
		</div>

		<div className="grille-livre">
			<div className="titre-livre">
				<h3>L'Atelier du brodeur</h3>
			</div>
			<div className="infos">
				<img src={brodeur} alt="Cover" />
				<p>Publishroom Factory ({ t('Publishroom', { ns: 'books' }) })</p>
				<p>02/11/2023</p>
				<a href="https://www.publishroom.com/librairie/1150-53196-l-atelier-du-brodeur-talents-savoir-faire-de-guenolee-milleret.html">{ t('link', { ns: 'books' }) }</a>
			</div>
			<div className="resume">
				<p>Cet ouvrage est un voyage dans l'histoire de la broderie comme vous n'en avez encore jamais entrepris… Pour ses lecteurs, l'auteure a concocté un itinéraire sur mesure&nbsp;: allers et retours d'hier à aujourd'hui en empruntant mille détours pour flâner en chemin. À chaque étape minutieusement préparée, s'ouvrent les portes d'un atelier de broderie. Le lecteur découvre les coulisses de la maison Lesage, partenaire incontournable de la haute couture depuis 1924, rencontre successivement Mathias Ouvrard, artiste et brodeur breton, la maître brodeuse Shikha Malik, Souheila Haddad, professeure certifiée de broderie japonaise, Julie Henocq, fondatrice de la Clinique du pull, puis Clémentine Brandibas, brodeuse organique. Des talents aussi divers que pointus, autant d'approches singulières du savoirfaire broderie…</p>
				<p>Au fil de cette enquête superbement illustrée, l'auteure propose une mise en miroir des pratiques de la tradition et des enjeux de la création contemporaine. Toute l'histoire socio-économique et culturelle de ce pan important de l'artisanat d'art y est dynamiquement brossée. Férus d'arts du fil et de mode, historiens des techniques, artisans, passionnés de patrimoine, étudiants, professionnels et créatifs en tout genre, chacun découvrira avec plaisir et profit ces pages animées d'une passion contagieuse pour l'art éternel et universel de la broderie.</p>
			</div>
		</div>

		<h2>{ t('title-essais', { ns: 'books' }) }</h2>

		<div className="grille-livre">
			<div className="titre-livre">
				<h3 className="titre-livre">Les vitrines du luxe</h3>
				<p>Une histoire culturelle du commerce haut de gamme et de ses espaces de vente</p>
			</div>
			<div className="infos">
				<img src={vitrines} alt="Cover" />
				<p>Éditions Eyrolles</p>
				<p>13/10/2016</p>
				<a href="https://www.eyrolles.com/Arts-Loisirs/Livre/les-vitrines-du-luxe-9782212144314/">{ t('link', { ns: 'books' }) }</a>
			</div>
			<div className="resume">
				<p>Cet ouvrage coécrit par Guénolée Milleret, historienne de la mode et des arts décoratifs, et Élodie de Boissieu, directrice de l'École internationale de marketing du luxe, est une plongée dans l'univers du commerce de luxe et ses enjeux économiques, artistiques et culturels passés et actuels.</p>
				<p>Le développement des boutiques de luxe - à partir du modèle historique parisien -, l'évolution des métiers et des pratiques commerciales et financières, la métamorphose des espaces de vente en termes de stratégie et d'innovation&nbsp;: en trois volets illustrés de près de 200 photos et documents d'archives, les <i>Vitrines du luxe</i> décrivent l'empire du luxe et de son imaginaire sur toute la société, et ses successives incarnations, jusqu'à la plus récente, internationale et virtuelle, d'un art de vivre.</p>
			</div>
		</div>

		<div className="grille-livre">
			<div className="titre-livre">
				<h3>Haute couture</h3>
				<p>Histoire de l'industrie de la création française – Des précurseurs à nos jours</p>
			</div>
			<div className="infos">
				<img src={hauteCouture} alt="Cover" />
				<p>Éditions Eyrolles</p>
				<p>13/05/2015</p>
			</div>
			<div className="resume">
				<p>Ce livre retrace le développement socioéconomique et culturel de l'activité de haute couture à travers l'histoire, depuis les précurseurs, les célèbres «&nbsp;marchands de modes&nbsp;» de l'Ancien Régime, puis le premier couturier au sens moderne du terme, Charles F. Worth. Remarquablement documenté, il s'intéresse à tous les acteurs de l'industrie de la création, à ses métiers, à ses instances représentatives, qui ont façonné une véritable exception française.</p>
				<p>Il est l'occasion, tout en évoquant l'histoire stylistique de la mode, de constater l'impact des «&nbsp;artisans du Beau&nbsp;» sur la société tout entière&nbsp;; et de montrer comment la haute couture actuelle, entre tradition et innovation, demeure un enjeu économique majeur par son image d'excellence à l'international.</p>
				<p><i>Préface d'Alexis Mabille</i></p>
			</div>
		</div>

		<div className="grille-livre">
			<div className="titre-livre">
				<h3>Dessin d'architecture et habitat moderne</h3>
			</div>
			<div className="infos">
				<img src={architecture} alt="Cover" />
				<p>Éditions Eyrolles</p>
				<p>23/10/2014</p>
				<a href="https://www.eyrolles.com/BTP/Livre/dessin-d-architecture-et-habitat-moderne-9782212140224/">{ t('link', { ns: 'books' }) }</a>
			</div>
			<div className="resume">
				<p><b>Des villas de villégiature aux habitations de la classe populaire, l'évolution de la maison privée à l'aube de la modernité, en quelque 600 magnifiques perspectives, élévations, coupes et plans.</b></p>
				<p>Premiers pavillons populaires, villas de villégiature, demeures bourgeoises et hôtels particuliers&nbsp;: l'habitat privé connaît une période faste entre le Second Empire et les années 1920. Ces maisons d'une infinie variété, plus que centenaires mais que nous habitons souvent encore, nous content maints usages oubliés, et tout simplement l'art de vivre de nos aïeux, entre esthétisme et nouvelles exigences de confort pour tous.</p>
				<p>Guénolée Milleret mêle dans cet ouvrage histoire de l'architecture, des moeurs, et du dessin d'architecture. De multiples informations, souvent pittoresques, sur l'apparition des usages modernes dans l'habitat accompagnent ces superbes planches produites par l'homme de l'art&nbsp;; des oeuvres au caractère artistique encore très marqué que le public n'avait plus eu le loisir d'admirer depuis bien longtemps.</p>
			</div>
		</div>

		<div className="grille-livre">
			<div className="titre-livre">
				<h3>Le décor intérieur en images</h3>
				<p>De l'empire au modernisme</p>
			</div>
			<div className="infos">
				<img src={decor} alt="Cover" />
				<p>Éditions Eyrolles</p>
				<p>26/09/2013</p>
				<a href="https://www.eyrolles.com/Arts-Loisirs/Livre/le-decor-interieur-en-images-9782212137347/">{ t('link', { ns: 'books' }) }</a>
			</div>
			<div className="resume">
				<p><b>L'âme des maisons d'autrefois</b></p>
				<p>De l'Empire au modernisme, cet ouvrage réunit plus de 800 planches retraçant l'évolution des styles dans le décor intérieur, mobilier et décor textile. Il s'agit d'une invitation à voyager dans le temps, un sésame pour entrer dans les demeures du XIXe siècle et du début du XXe. Ces dessins aussi rares qu'exquis permettent de comprendre comment les fabricants de meubles, les tapissiers-décorateurs et les architectes d'alors composaient leurs ensembles.</p>
				<p>S'il décrit page après page la grammaire des styles décoratifs élaborée par les hommes de l'art, cet ouvrage n'est pas une «&nbsp;histoire des styles&nbsp;» à proprement parler. Illustré par des documents représentatifs de chaque période, ponctué de focus thématiques, il évoque les ambiances des intérieurs bourgeois depuis l'Empire jusqu'aux années 1920 et à l'Art déco. Cette collection de gravures et de lithographies rarement présentées en un si large ensemble et sur une si longue période, constitue une ressource documentaire d'une richesse inouïe, propre à nourrir la créativité des designers et des passionnés de «&nbsp;déco&nbsp;».</p>
				<p>Ce livre rend également hommage aux artistes qui ont signé ces oeuvres. Qu'ils proviennent de revues prestigieuses ou de simples catalogues de vente de maisons disparues, ces trésors ont tous été collectés pour leur beauté, leur rareté et leur ingéniosité - comme on compose amoureusement un album de souvenirs - et magnifiquement restaurés par l'auteur.</p>
			</div>
		</div>

		<div className="grille-livre">
			<div className="titre-livre">
				<h3>La mode du XIXe siècle en images</h3>
			</div>
			<div className="infos">
				<img src={modeXIXe} alt="Cover" />
				<p>Éditions Eyrolles</p>
				<p>04/10/2012</p>
			</div>
			<div className="resume">
				<p>«&nbsp;L'histoire de la mode n'est pas une histoire d'invention, mais une tradition de transmission. De génération en génération, les marchands de mode et les couturières, puis les confectionneurs et grands couturiers jusqu'aux créateurs et designers d'aujourd'hui&nbsp;: tous transmettent leur savoir-faire et leur interprétation de la mode, qui constituent un fabuleux patrimoine incessamment renouvelé.&nbsp;» Guénolée Milleret</p>
				<p>Du Directoire à la Belle Epoque, <i>La mode du XIXe siècle en images</i> révèle l'extraordinaire variété des courants de mode qui ont balayé cette période fondatrice de la mode contemporaine.</p>
				<p>Ce livre réunit plus de 700 gravures, pures merveilles de précision et de délicatesse réalisées par de véritables maîtres d'art graveurs et dessinateurs, tels Jules David ou Anaïs Toudouze, pour l'abondante presse de mode de l'époque. Il offre à tous, professionnels et passionnés de mode, un éblouissant répertoire des toilettes du siècle de l'élégance.</p>
			</div>
		</div>

		<div className="grille-livre">
			<div className="titre-livre">
				<h3>Le petit musée des modes – Tenues des grands jours</h3>
			</div>
			<div className="infos">
				<img src={petitMuseeModes} alt="Cover" />
				<p>Éditions Falbalas</p>
				<p>22/09/2011</p>
			</div>
			<div className="resume">
				<p>Véritable réservoir d'images* anciennes et inédites, <i>Tenues des grands jours</i> nous fait découvrir l'extraordinaire audace des costumes folkloriques des régions françaises et européennes ainsi que la délicatesse des trousseaux et des robes de mariée, depuis le Premier Empire jusqu'à la Belle Epoque.</p>
				<p>Au XVIIIe siècle, Rose Bertin, la très avant-gardiste «&nbsp;ministre des modes&nbsp;» de Marie-Antoinette, prétendait déjà qu'«&nbsp;il n'y a de nouveau que ce qui est oublié&nbsp;». Dans cette optique, <i>Tenues des grands jours</i> révèle un trésor jusqu'alors enfoui dans des cabinets de collectionneurs ou dans les archives des musées, autant dire une profusion d'idées pour les stylistes, designers et costumiers…</p>
				<p>L'origine et la date de chaque image ainsi que l'appellation des sujets représentés apportent aux lecteurs des points de repère pratiques et instructifs.</p>
				<p>*&nbsp;La formule «&nbsp;réservoir d'images&nbsp;» est de Jeanne Lanvin qui, à l'instar de tant de créateurs d'hier et d'aujourd'hui, y puisait nombre d'idées pour créer ses célèbres robes de style.</p>
			</div>
		</div>

		<div className="grille-livre">
			<div className="titre-livre">
				<h3>Modes du XXe siècle – Le Streetwear</h3>
			</div>
			<div className="infos">
				<img src={streatwear} alt="Cover" />
				<p>Éditions Falbalas</p>
				<p>27/04/2011</p>
			</div>
			<div className="resume">
				<p>Où est né le streetwear&nbsp;?</p>
				<p>Quels phénomènes ont étendu son influence au point d'en faire l'un des mouvements de mode les plus pérennes et les plus populaires de ces dernières décennies&nbsp;?</p>
				<p>Quelles sont les pièces emblématiques des modes de la rue&nbsp;?</p>
				<p><i>Modes du XXe siècle – Le Streetwear</i> décode toutes les pièces du vestiaire et les looks des modes de la rue. Depuis les sports de glisse à la relève hip-hop puis gangsta, toutes les facettes des influences du streetwear sont décryptées et toutes ses pièces emblématiques (chemise Pendleton, chino, blouson teddy, baggy, hoodie, veste M–65, baskets et tant d'autres) sont croquées dans les attitudes très typées qui signent ces modes. Source d'inspiration ou empreinte, le sportswear, l'urbanwear et le street style complètent ce panorama…</p>
			</div>
		</div>

		<h2>{ t('title-fictions', { ns: 'books' }) }</h2>

		<div className="grille-livre">
			<div className="titre-livre">
				<h3>Les cousettes du Petit Écho</h3>
				<p>Pionnières de la mode circulaire</p>
			</div>
			<div className="infos">
				<img src={cousettes} alt="Cover" />
				<p>Publishroom Factory ({ t('Publishroom', { ns: 'books' }) })</p>
				<p>31/03/2023</p>
				<a href="https://www.publishroom.com/librairie/989-52955-les-cousettes-du-petit-echo-pionnieres-de-la-mode-circulaire-guenolee-milleret.html#/">{ t('link', { ns: 'books' }) }</a>
			</div>
			<div className="resume">
				<p>La mode circulaire nous engage aujourd'hui à préférer les articles de seconde main, à employer les stocks dormants, à réévaluer des vêtements démodés à ne surtout pas jeter. Le principe consiste à offrir une nouvelle et belle vie à un existant déchu, en privilégiant un mode de transformation ou de fabrication local.</p>
				<p>Outre la qualification récente de la mode circulaire comme l'engrenage essentiel et vertueux pour contribuer à préserver notre planète, quand en observe-t-on les démarches pionnières&nbsp;? Peut-être faut-il commencer par en chercher les traces dans les usages de nos grands-mères et arrière-grands-mères, ces oubliées de l'histoire de la mode… Les cousettes du XXe siècle sont les héritières d'une longue tradition d'élégance pragmatique, un principe qu'aiguillonne la presse de mode familiale, le <i>Petit Écho de la mode</i> en particulier, apprécié comme une bible de la mode circulaire… avant la lettre&nbsp;!</p>
				<p>Alors plongeons dans le fantastique corpus de dessins publiés des décennies durant dans le <i>Petit Écho de la mode</i>… Laissons l'imaginaire se développer autour de ces vies minuscules, donnons à nos cousettes un visage, un quotidien et des projets. Incarnons cette approche historique des origines de la mode circulaire&nbsp;: ici, une garçonne des années 1920 en robe de sport, là, une sirène glamour de l'entre-deux-guerres, ailleurs enfin, une élégante sanglée dans un tailleur New Look.</p>
				<p>Ces poupées de papier s'animent, s'échappent des colonnes de texte et nous accueillent spontanément dans leur monde d'ingéniosité…</p>
			</div>
		</div>

		<div className="grille-livre">
			<div className="titre-livre">
				<h3>Histoire de la mode et de la cuisine en 6 escales</h3>
			</div>
			<div className="infos">
				<img src={fantomes} alt="Cover" />
				<p>Éditions Jourdan</p>
				<p>13/01/2022</p>
			</div>
			<div className="resume">
				<p>Au détour d'une rêverie contrariée par le couvre-feu, Guénolée Milleret décide de s'évader et de nous téléporter avec elle dans le passé afin de retracer, au gré de ses escales temporelles, deux cents ans d'Histoire de la mode et de la cuisine.</p>
				<p>Dans cet ouvrage aux airs de reconstitution historique vivante et colorée, l'auteure aborde six époques, dresse le portrait de six figures féminines autour des modes vestimentaires et de bons plats mijotés. Pour ce faire, elle a collecté en abondance les échantillons de mode et de cuisine nécessaires afin de contextualiser chaque étape dans le temps, et reconstruit une réalité fantasmée à l'aide de quelques alibis fictionnels. On sent, on voit, on goûte, on touche&nbsp;: c'est un régal synesthésique, porté par un sens du détail rigoureux et une érudition manifeste.</p>
				<p>Entre légèreté drolatique, poésie du verbe et élégance, venez à la rencontre de ces personnages de la grande et de la petite histoire, délicatement ciselés par une professeure d'histoire de la mode…</p>
			</div>
		</div>

		<div className="grille-livre">
			<div className="titre-livre">
				<h3>Les folles heures de la vie d'une parisienne</h3>
			</div>
			<div className="infos">
				<img src={follesHeures} alt="Cover" />
				<p>Éditions Eyrolles</p>
				<p>17/10/2013</p>
				<a href="https://www.eyrolles.com/Loisirs/Livre/les-folles-heures-de-la-vie-d-une-parisienne-9782212138108/">{ t('link', { ns: 'books' }) }</a>
			</div>
			<div className="resume">
				<p>S'inspirant des <i>Heures de la Parisienne</i>, célèbre recueil de gravures du XIXe siècle mettant en scène une élégante à chaque heure de la journée, Angéline Mélin opère une rencontre improbable entre la fashionista contemporaine et l'élégante des années 1920, commentée par la plume de Guénolée Milleret, historienne de la mode.</p>
				<p>Hantant les mêmes lieux, du Bon Marché à la Closerie des Lilas en passant par Saint-Germain-des-Prés, elles s'y croisent en soeurs jumelles, aussi coquettes, hyperactives, gourmandes et amoureuses de leur ville l'une que l'autre. Après un bref coup d'oeil sur leur allure respective, elles s'amusent à se faire valoir l'une l'autre&nbsp;: la mode n'est qu'un éternel recommencement pour l'intemporelle Parisienne.</p>
			</div>
		</div>
	</>);
}

BooksPage.namespaces = ['books'];

export default BooksPage;