import { IoHeart } from 'react-icons/io5';

import LocalizedLink from './LocalizedLink';

function GoToFavourites() {
	return (<div id="heart">
		<LocalizedLink to="/favourites"><IoHeart /></LocalizedLink>
	</div>);
}

export default GoToFavourites;