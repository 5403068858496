import useLanguageNavigator from '../hooks/useLanguageNavigator';

import flagFrench from '../assets/flag-French.svg';
import flagEnglish from '../assets/flag-English.svg';

function SwitchLanguage() {
	const { handleChangeLanguage } = useLanguageNavigator();  // Destructure to get handleChangeLanguage function

	return (
		<>
			<button onClick={() => handleChangeLanguage('fr')}><img src={flagFrench} alt="FR" /></button>
			<button onClick={() => handleChangeLanguage('en')}><img src={flagEnglish} alt="EN" /></button>
		</>
	);
}

export default SwitchLanguage