import { useTranslation } from 'react-i18next';

import LocalizedLink from './LocalizedLink';

import '../stylesheets/galerie.css';

function Item(props) {
	const { t } = useTranslation([]);
	
	let img_src = require(`../assets/${props.name}.jpg`);
	const tr_key = 'subcategories.' + props.name;
	const searchLink = `/search?categorie=${props.categorie_id}&sous_categorie=${props.sous_categorie_id}`

	return (
		<div className="item-connected">
			<LocalizedLink to={searchLink} rel="nofollow">
				<img src={img_src} alt="Cover" />
				<p className="sous-categorie">{ t(tr_key, { ns: 'db' }) }</p>
			</LocalizedLink>
		</div>
	);
}

function HomeGalleryConnected(props) {
	return (
		<div className="galerie home-galerie">
			{props.subcategories.map ((s, index) => (<Item key={s} name={s} categorie_id={props.categorie_id} sous_categorie_id={props.start + index} />))}
		</div>
	);
}

export default HomeGalleryConnected;