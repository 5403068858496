import '../stylesheets/galerie.css';

function Item(props) {
	let img_src = require(`../assets/accueil-${props.category}-${props.number}.jpg`);

	return (
		<div className="item-visit">
			<img src={img_src} alt="Sample image" />
		</div>
	);
}

function HomeGalleryVisitor(props) {
	return (
		<div className="galerie home-galerie">
			{Array.from({ length: props.quantity }).map((_, i) => (
    			<Item key={i} category={props.category} number={i} />
  			))}
		</div>
	);
}

export default HomeGalleryVisitor;