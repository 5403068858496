// customDetector.js
export const customDetector = {
	name: 'customDetector',
	lookup: (options) => {
		// Check if the language is stored in localStorage
		const storedLanguage = localStorage.getItem('userLanguage');
		if (storedLanguage) {
			return storedLanguage;
		}

		// Detect language from path (if "fr" is explicitely demanded)
		const path = window.location.pathname;
		if (path.startsWith('/fr')) {
			// Store the selected language in localStorage
			localStorage.setItem('userLanguage', 'fr');
			return 'fr';
		}
		
		// Try to get the language from the browser's settings
		let browserLanguage = window.navigator.language || window.navigator.userLanguage;
		browserLanguage = browserLanguage.split('-')[0];

		// Fallback to 'en' if the browser language is not supported
		let userLanguage = options.fallbackLanguage;

		// Check if the language from the browser settings is available in supported languages
		if (browserLanguage && options.supportedLngs.includes(browserLanguage)) {
			userLanguage = browserLanguage;
		}

		// Store the selected language in localStorage
		localStorage.setItem('userLanguage', userLanguage);
		return userLanguage;
	},
	cacheUserLanguage: (lng) => {
		// Store the selected language in localStorage
		localStorage.setItem('userLanguage', lng);
	}
};