import { useTranslation } from 'react-i18next';
import MetaTags from './MetaTags';

function LegalNoticesPage() {
	const { t } = useTranslation(['legal_notices']);

	return (<>
		<MetaTags />

		<h1>{ t('title', { ns: 'legal_notices' }) }</h1>
		<div>
			<p>Conformément aux dispositions des articles 6-III et 19 de la loi pour la Confiance dans l'Économie Numérique nous vous informons que&nbsp;:</p>
			<p>Le présent site internet est édité par l'entreprise individuelle Guénolée MILLERET (marque commerciale images GuenoMiller®) dont le siège est situé au 73 rue Marcel Dassault - 92100 BOULOGNE BILLANCOURT - FRANCE.
				<br />N° SIRET&nbsp;: 524 627 494 00015
				<br />Adresse de courrier électronique&nbsp;: guenolee@imagesguenomiller.com</p>
			<p>Directrice de la publication du site&nbsp;: Guénolée MILLERET</p>
			<p>Hébergement&nbsp;: Société o2switch - <a href="https://www.o2switch.fr/" rel="nofollow">www.o2switch.fr</a> - N° SIRET&nbsp;: 510 909 807 00032 RCS Clermont Ferrand - Siège social&nbsp;: CHE DES PARDIAUX - 63000 CLERMONT FERRAND - FRANCE</p>
			<p>Design et développement du site&nbsp;: Thaïs MILLERET</p>
		</div>
	</>);
}

LegalNoticesPage.namespaces = ['legal_notices'];

export default LegalNoticesPage;