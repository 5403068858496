import NotFoundPage from '../components/NotFoundPage';
import HomePage from '../components/HomePage';
import AboutPage from '../components/AboutPage';
import AccountPage from '../components/AccountPage';
import BooksPage from '../components/BooksPage';
import CompositionsPage from '../components/CompositionsPage';
import ContactPage from '../components/ContactPage';
import FavouritesPage from '../components/FavouritesPage';
import ForbiddenPage from '../components/ForbiddenPage';
import ForgottenPasswordPage from '../components/ForgottenPasswordPage';
import LegalNoticesPage from '../components/LegalNoticesPage';
import LoginPage from '../components/LoginPage';
import MediaPage from '../components/MediaPage';
import RegisterPage from '../components/RegisterPage';
import ResetPasswordPage from '../components/ResetPasswordPage';
import SearchPage from '../components/SearchPage';
import SourcePage from '../components/SourcePage';
import TosPage from '../components/TosPage';

export const useGenerateRoutes = () => {

	// Function to generate routes with language prefixes
	const generateRoutes = (languagePrefix = '') => {
		return [
			{
				path: `${languagePrefix}/`,
				element: <HomePage />,
			},
			{
				path: `${languagePrefix}/about`,
				element: <AboutPage />,
			},
			{
				path: `${languagePrefix}/account`,
				element: <AccountPage />,
			},
			{
				path: `${languagePrefix}/books`,
				element: <BooksPage />,
			},
			{
				path: `${languagePrefix}/compositions`,
				element: <CompositionsPage />,
			},
			{
				path: `${languagePrefix}/contact`,
				element: <ContactPage />,
			},
			{
				path: `${languagePrefix}/favourites`,
				element: <FavouritesPage />,
			},
			{
				path: `${languagePrefix}/forbidden`,
				element: <ForbiddenPage />,
			},
			{
				path: `${languagePrefix}/forgotten_password`,
				element: <ForgottenPasswordPage />,
			},
			{
				path: `${languagePrefix}/legal_notices`,
				element: <LegalNoticesPage />,
			},
			{
				path: `${languagePrefix}/login`,
				element: <LoginPage />,
			},
			{
				path: `${languagePrefix}/media/:code_image`,
				element: <MediaPage />,
			},
			{
				path: `${languagePrefix}/register`,
				element: <RegisterPage />,
			},
			{
				path: `${languagePrefix}/reset_password/:user_id/:unique_string`,
				element: <ResetPasswordPage />,
			},
			{
				path: `${languagePrefix}/search`,
				element: <SearchPage />,
			},
			{
				path: `${languagePrefix}/source/:source_id`,
				element: <SourcePage />,
			},
			{
				path: `${languagePrefix}/terms_of_service`,
				element: <TosPage />,
			},
			{
				path: `*`,
				element: <NotFoundPage />,
			},
		];
	};

	return {generateRoutes};
};
