import { useTranslation } from 'react-i18next';

import PlancheContact from './PlancheContact';

function SearchResults(props) {
	// props.results to use search results Array from SearchPage
	const { t } = useTranslation(['search']);

	return (<div className="search-results">
		<h2>{t('gallery.title', { ns: 'search' })}</h2>

		<PlancheContact results={props.results} loading={props.loading} no_results={t('no_results', { ns: 'search' })} searchGalerieRef={props.searchGalerieRef} />
	</div>);
}

export default SearchResults;