import { useTranslation } from 'react-i18next';
import MetaTags from './MetaTags';

import '../stylesheets/contact.css';

import logoVDI from '../assets/logo-VDI-imagette.png';

function ContactPage() {
	const { t } = useTranslation(['contact']);

	return (<>
		<MetaTags />

		<h1>{ t('title', { ns: 'contact' }) }</h1>

		<div>
			<h2>{ t('founder', { ns: 'contact' }) }</h2>
			<ul>
				<li>{ t('mail', { ns: 'contact' }) } guenolee@imagesguenomiller.com</li>
				<li>Instagram{ t('deux-points', { ns: 'common' }) } <a href="https://www.instagram.com/imagesguenomiller">@imagesguenomiller</a></li>
				<li>LinkedIn{ t('deux-points', { ns: 'common' }) } <a href="https://www.linkedin.com/in/guenolee-milleret/">@guenolee-milleret</a></li>
			</ul>
		</div>

		<div>
			<h2>{ t('admin', { ns: 'contact' }) }</h2>
			<ul>
				<li>{ t('mail', { ns: 'contact' }) } thais@imagesguenomiller.com</li>
			</ul>
		</div>

		<div>
			<h2>{ t('buy-images', { ns: 'contact' }) }</h2>
			<p>{ t('text_buy-images', { ns: 'contact' }) }</p>
		</div>

		<div id="contact-logo">
			<img src={logoVDI} alt="Logo" />
		</div>
	</>);
}

ContactPage.namespaces = ['contact'];

export default ContactPage;